import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  HubHeader,
  DateButton,
  HubFooter,
  RegistrationForm,
  Speakers,
  Faq,
  BulletPoint,
  UpcomingEvents,
  PreviousEvents,
  SEO,
  EmailHandler,
  VideoCarousel
} from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { gridContainer, fullWidth, H2, H3, H4, P, Grid, defaultColors, breakpoints } from 'styles';
import { Blob1, Blob2 } from 'assets/svgs';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { motion } from 'framer-motion';
import { useIntersection, useEventData } from 'hooks';

const FormSVG = ({ children, xoffset, yoffset }) => {
  const [elRef, observerEntry] = useIntersection({ threshold: 0 });
  return (
    <SVG
      ref={elRef}
      initial={{ opacity: 0, x: xoffset, y: yoffset }}
      animate={
        observerEntry.isIntersecting
          ? { opacity: 1, x: 0, y: 0 }
          : { opacity: 0, x: xoffset, y: yoffset }
      }>
      {children}
    </SVG>
  );
};

const Home = () => {
  const { theme, mobile, scrollTo, registerRef, faqRef } = useContext(LocalContext);
  const { loading, user, firebase } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;
  const { edges: events } = useEventData();

  // useEffect(() => {
  //   if (!loading && firebase && user) {
  //     navigate('/livestream/')
  //   }
  // }, [user, firebase, loading]);

  const carouselVideoData = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/carousel-videos/homepage/" } }
          sort: { fields: [frontmatter___id] }
        ) {
          nodes {
            frontmatter {
              id
              title
              url
              thumb {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    formats: [AUTO, WEBP, AVIF]
                    width: 1162
                    height: 654
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <PageWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5, duration: 1 }}
      $theme={theme}>
      <SEO pageSpecificTitle="Register" />
      <EmailHandler />
      <HubHeader scrollToRegister={() => scrollTo(registerRef)} colors={defaultColors} />
      <Main ref={registerRef} $theme={theme}>
        <About>
          <H2 $theme={theme}>Participate in Workshops</H2>
          <P $theme={theme}>
            The Epilepsy in English workshops are a series of four events designed to break down
            barriers between all stakeholders in epilepsy research. Alongside FutureNeuro SFI
            Research Centre for Chronic and Rare Neurological Diseases, we want to make epilepsy
            research accessible to all. Our ultimate goal is to educate, equip and empower people
            living with epilepsy to engage with research.
          </P>
          <P $theme={theme}>
            The workshops will take place throughout November 2021 and are themed around key
            priority topics, as defined by the epilepsy community. Each will feature a world-leading
            FutureNeuro researcher and include both presentations and small group discussions, to
            ensure that everyone’s voice is heard. Do join us and help us set the future agenda for
            epilepsy research.
          </P>
          <br />
        </About>
        <WorkshopDates user={user}>
          <P $theme={theme}>
            <span style={{ color: defaultColors.tertiary, fontWeight: 800 }}>
              NOVEMBER WORKSHOP DATES:
            </span>
            <br />
            {/* <span style={{ color: '#000', fontWeight: 800 }}>17th Nov</span> - Temporal Lobe
            Epilepsy – Prof David Henshall
            <br /> */}
            <span style={{ color: '#000', fontWeight: 800 }}>25th Nov</span> - Gene Therapy – Dr
            Matt Campbell & Dr Cristina Reschke
            <br />
            <br />
            <span style={{ color: defaultColors.tertiary, fontWeight: 800 }}>
              TIME OF ALL WORKSHOPS:
            </span>
            <span style={{ color: '#000' }}> 6:30pm - 8pm</span>
            <br />
          </P>
          <P
            dangerouslySetInnerHTML={{
              __html: user
                ? 'Places are limited so please pick your most preferred workshop.'
                : 'Register for the workshop you wish to attend in the registration form across.<br />Places are limited so please pick your most preferred workshop.'
            }}
          />
        </WorkshopDates>
        {!mobile && !user && (
          <RegFormGrid style={{ position: 'relative' }}>
            <FormSVG xoffset={50} yoffset={50}>
              <Blob1 />
            </FormSVG>
            <FormSVG xoffset={50} yoffset={50}>
              <Blob2 />
            </FormSVG>
            {!currentUser && <RegistrationForm colors={defaultColors} />}
          </RegFormGrid>
        )}
      </Main>
      {/* {currentUser && (
        <>
          <Container $theme={theme} className="muted">
            <H4 style={{ textAlign: 'center' }}>Upcoming Events</H4>
            <UpcomingEvents />
          </Container>
          <Container $theme={theme}>
            <H4 style={{ textAlign: 'center', marginBottom: '.5em' }}>Previous Events</H4>
            <P style={{ textAlign: 'center' }}>
              Below you can view a selection of our previous events with a recording of the
              livestream and speaker information.
            </P>
            <PreviousEvents />
          </Container>
        </>
      )} */}
      <VideoCarousel
        onHomepage
        carouselVideos={carouselVideoData.allMarkdownRemark.nodes.map((node) => node.frontmatter)}
      />
      <AllSpeakers>
        <SpeakerContainer>
          <H3 style={{ color: theme.bg, textAlign: 'center', marginBottom: '50px' }} theme={theme}>
            Keynote Speakers
          </H3>
          <Speakers />
        </SpeakerContainer>
      </AllSpeakers>
      {/* <Container id="faq" $theme={theme} ref={faqRef}>
        <Title>Frequently Asked Questions</Title>
        <Grid>
          <Faq />
        </Grid>
      </Container> */}
      <HubFooter />
    </PageWrapper>
  );
};

const AllSpeakers = styled.section`
  ${gridContainer}
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
  padding: 3rem 0;
  width: 100%;

  @media (min-width: 768px) {
    padding: 6rem 0 3rem;
    grid-column: 1/13 !important;
  }

  @media (min-width: 1600px) {
    /* padding: 6rem 0 3rem; */
  }
`;

const SpeakerContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  position: relative;

  h3 {
    font-size: 2rem;
  }

  @media (min-width: 768px) {
    padding: 2rem;

    h3 {
      /* font-size: 2.625rem; */
    }
  }

  @media (min-width: 1600px) {
    padding: 4rem 2rem;
  }
`;

const WorkshopDates = styled.div`
  ${({ user }) =>
    user
      ? css`
          grid-column: 1/7;
          grid-row: 2/3;

          ${breakpoints.lg} {
            grid-column: 7/11;
            grid-row: 1/2;
            padding-top: 6.3rem;
          }
        `
      : css`
          grid-column: 1/6;
          grid-row: 2/3;
        `};

  p {
    font-size: 1rem;
    line-height: 1.5em;
  }
`;

const RegFormGrid = styled.div`
  grid-row: 1/4;
  grid-column: 7/11;
  position: relative;
  form {
    box-shadow: 6px 6px 30px 3px rgba(0, 0, 0, 0.35);
    position: relative;
    width: 100%;
    z-index: 3;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
`;

const Circle1 = styled(Circle)`
  background: #eaf5f9;
  height: 12rem;
  width: 12rem;
`;

const Circle2 = styled(Circle)`
  background: ${({ $theme }) => $theme.fg};
  height: 8rem;
  width: 8rem;
`;

const Main = styled.section`
  ${gridContainer}
  color:  ${({ $theme }) => $theme.textColor};
  grid-gap: unset;
  padding: 3.75rem 1.25rem;
  ${breakpoints.sm} {
    padding: 6rem 0;
    padding-bottom: 3rem;
  }
  ${breakpoints.lg} {
    grid-gap: unset;
    padding: 6rem 0;
  }
  ${breakpoints.xl} {
    padding: 6rem 0;
  }
`;

const About = styled.div`
  grid-column: 1/7;
  grid-row: 1/2;
  position: relative;
  ${breakpoints.lg} {
    grid-column: 1/6;
  }

  h2 {
    font-size: 2.625rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5em;
  }
`;

const SVG = styled(motion.div)`
  display: block;
  opacity: 1;
  position: absolute;
  transition-duration: 1s;
  z-index: 1;
  :nth-child(1) {
    left: -32.5%;
    top: -15%;
    z-index: 1;
    svg {
      animation: spin 3s ease-in-out infinite;
      width: 635px;
    }
  }
  :nth-child(2) {
    bottom: -15%;
    right: -20%;
    z-index: 1;
    svg {
      animation: spin 3s ease-in-out infinite;
      width: 477px;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(5deg);
    }
    10% {
      transform: rotate(0deg);
    }
  }
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  text-align: center;
  ${fullWidth}
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const Container = styled.section`
  color: ${({ $theme }) => $theme.textColor};
  ${gridContainer}
  ${H4} {
    grid-column: 1/7;
  }
  ${P} {
    grid-column: 1/7;
  }
  padding: 3rem 1rem;
  ${breakpoints.sm} {
    padding: 6rem 1.25rem;
  }
  ${breakpoints.lg} {
    padding: 6rem 0;
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    ${H4} {
      grid-column: 2/12;
    }
    ${P} {
      grid-column: 2/12;
    }
  }
  ${breakpoints.xl} {
    padding: 6rem 0;
    grid-column: 3/11;
  }
  &.muted {
    background-color: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : '#f9f9f9')};
  }
`;

const PageWrapper = styled(motion.div)`
  background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
  box-sizing: border-box;
  display: grid;
  filter: ${({ $theme }) => ($theme.className === 'grayscale' ? 'grayscale(100%)' : 'none')};
  grid-column: 1/7;
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default Home;
